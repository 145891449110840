@import './assets/sass/styles.scss';

.App {
  background-image: url('./assets/images/background.jpg');
  min-height: 100vh;
  min-width: 100vw;
  color: white;
  .bold {
    font-weight: 600;
  }
  .red-dash {
    height: 5px;
    width: 55px;
    background-color: #dc0506;
  }
}
