@import '../../assets/sass/font-face';

.Footer {
  background-color: black;
  .fancy-button {
    background-image: url('../../assets/images/button_no_text.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 293px;
    height: 79px;
    font-family: Bourgeois, Roboto, sans-serif;
    color: white;
    display: flex;
    align-items: center;
    padding: 0px 50px 0 25px;
    float: right;
    position: relative;
    top: -39px;
    right: 140px;
    text-align: center;
    &:hover {
      filter: brightness(80%);
    }
    .eesti-ee-span {
      display: contents;
      font-weight: bold;
    }
  }
  .container {
    padding: 80px 0;
    .columns {
      &.header-columns {
        font-family: Bourgeois, Roboto, sans-serif;
        font-weight: 600;
      }
      &.link-columns {
        font-size: 1.1rem;
        .column {
          span {
            font-family: Bourgeois, Roboto, sans-serif;
            display: block;
          }
          a {
            font-family: Bourgeois, Roboto, sans-serif;
            color: #474747;
            display: block;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      &.campaign-columns {
        font-family: Bourgeois, Roboto, sans-serif;
        border-bottom: 2px solid white;
      }
      &.privacy-columns {
        .column {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          flex-direction: row;
          a {
            padding: 0 10px;
            font-family: Bourgeois, Roboto, sans-serif;
            color: #474747;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

/* MOBILE */
@media (max-width: 992px) {
  .Footer {
    .fancy-button {
      float: left;
      right: auto;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .container {
      .columns {
        margin-left: auto;
        margin-right: auto;
        &.privacy-columns {
          .column {
            justify-content: flex-start;
            align-items: flex-start;
            a {
              &:first-child {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}
