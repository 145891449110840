@import '../../assets/sass/font-face';

.Section4 {
  background-image: url('../../assets/images/section_4_bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .columns {
    padding: 50px 100px 80px 200px;
    .column {
      &.text-column {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 0 100px 0 0;
        font-size: 1.5rem;
        h2 {
          color: #dc0506;
          font-family: BourgeoisUltra, Roboto, sans-serif;
          font-size: 3rem;
          font-weight: 800;
          padding-bottom: 10px;
        }
        p {
          font-family: Bourgeois, Roboto, sans-serif;
          padding-bottom: 30px;
        }
        a {
          color: white;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &.video-column {
        padding: 30px 0 0 50px;
        .video-container {
          box-shadow: 0px 0px 85px 5px rgba(0, 0, 0, 0.7);
          -webkit-box-shadow: 0px 0px 85px 5px rgba(0, 0, 0, 0.7);
          -moz-box-shadow: 0px 0px 85px 5px rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
}

/* MOBILE */
@media (max-width: 992px) {
  .Section4 {
    .columns {
      margin-left: auto;
      margin-right: auto;
      padding: initial;
      .column {
        &.text-column {
          padding: 25px;
          font-size: 1rem;
          h2 {
            font-size: 2rem;
          }
        }
        &.video-column {
          padding: 25px;
        }
      }
    }
  }
}

/* SMALL SCREEN ONLY */
@media (min-width: 992px) and (max-width: 1440px) {
  .Section4 {
    .columns {
      .column {
        &.text-column {
          padding: 25px;
        }
      }
    }
  }
}
