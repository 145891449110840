@import '../../assets/sass/font-face';

.Section1 {
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 100%;
  }

  .video-responsive video {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  background-image: url('../../assets/images/section_1_bg_new.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .fancy-button {
    font-family: Bourgeois, Roboto, sans-serif;
    background-image: url('../../assets/images/button_no_text.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 293px;
    height: 79px;
    color: white;
    display: flex;
    align-items: center;
    padding: 0px 50px 0 25px;
    text-align: center;
    &:hover {
      filter: brightness(80%);
    }
    .eesti-ee-span {
      display: contents;
      font-weight: bold;
    }
  }
  .language-button {
    position: absolute;
    right: 175px;
    top: 30px;
    background-color: #dc0506;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 15px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      filter: brightness(80%);
    }
  }
  .columns {
    .column {
      &.logo-column {
        padding: 80px 200px 0 200px;
      }
      &.text-column {
        padding: 50px 200px 0px 200px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        h1 {
          font-family: BourgeoisUltra, Roboto, sans-serif;
          text-align: center;
          font-size: 8rem;
          font-weight: 600;
          line-height: 1;
          text-shadow: 0px 0px 15px rgb(92, 255, 133);
          span {
            display: block;
            &.left-line,
            &.right-line {
              height: 9px;
              width: 172px;
              margin-bottom: 15px;
              display: inline-block;
            }
            &.left-line {
              margin-right: 5px;
              background-image: url('../../assets/images/left_line.png');
            }
            &.right-line {
              margin-left: 5px;
              background-image: url('../../assets/images/right_line.png');
            }
          }
        }
        h2 {
          font-family: BourgeoisUltra, Roboto, sans-serif;
          text-align: center;
          font-size: 4rem;
          font-weight: 600;
          display: inline-block;
        }
        p {
          font-family: Bourgeois, Roboto, sans-serif;
          text-align: end;
          max-width: 860px;
          padding: 20px 0;
          font-size: 2rem;
          a {
            color: white;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        a {
          img {
            &:hover {
              filter: brightness(80%);
            }
          }
        }
      }
      &.video-column {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .video-container {
          margin: 20px 0 100px 0;
          width: 80vw;
          box-shadow: 0px 0px 85px 5px rgba(0, 0, 0, 0.7);
          -webkit-box-shadow: 0px 0px 85px 5px rgba(0, 0, 0, 0.7);
          -moz-box-shadow: 0px 0px 85px 5px rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
}

/* MOBILE */
@media (max-width: 992px) {
  .Section1 {
    .language-button {
      right: 30px;
    }
    .columns {
      margin-left: auto;
      margin-right: auto;
      .column {
        &.logo-column {
          padding: initial;
          img {
            padding: 30px 40px 10px 40px;
          }
        }
        &.text-column {
          padding: 0 0 50px 0;
          justify-content: center;
          align-items: center;
          h1 {
            text-align: center;
            font-size: 2rem;
            span {
              &.left-line,
              &.right-line {
                height: 4px;
                width: 50px;
                margin-bottom: 7px;
                background-size: contain;
              }
            }
          }
          h2 {
            font-size: 1.5rem;
          }
          p {
            max-width: none;
            text-align: start;
            padding: 20px 30px;
            font-size: 1rem;
          }
        }
        &.video-column {
          .video-container {
            margin: 40px 0 40px 0;
            width: 90vw;
          }
        }
      }
    }
  }
}
