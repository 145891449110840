@import '../../assets/sass/font-face';

.Section3 {
  background-image: url('../../assets/images/section_3_bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: black;
  .columns {
    .column {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      &.text-column {
        padding-bottom: 60px;
        .tabbed-text-container {
          max-width: 80vw;
          margin: 110px 0 0 0;
          .tab {
            color: #dc0506;
            font-family: BourgeoisUltra, Roboto, sans-serif;
            font-weight: 800;
            font-size: 3rem;
            background-color: #efefefcb;
            padding: 5px 40px 0px 20px;
            position: absolute;
            margin-top: -4.8rem;
            clip-path: polygon(0 0, 90% 0%, 100% 100%, 0% 100%);
            -webkit-clip-path: polygon(0 0, 90% 0%, 100% 100%, 0% 100%);
          }
          .text {
            background-color: #efefefcb;
            padding: 5px 20px 60px 20px;
            font-family: Bourgeois, Roboto, sans-serif;
            font-size: 1.5rem;
            .columns {
              margin-top: -0.35rem;
              .column {
                p {
                  padding: 10px 0;
                }
                a {
                  color: black;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* MOBILE */
@media (max-width: 992px) {
  .Section3 {
    .columns {
      margin-left: auto;
      margin-right: auto;
      .column {
        &.text-column {
          .tabbed-text-container {
            max-width: 90vw;
            .tab {
              font-size: 2rem;
              margin-top: -3.3rem;
            }
            .text {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
