@import '../../assets/sass/font-face';

.Section2 {
  .columns {
    .column {
      display: flex;
      justify-content: center;
      align-items: center;
      &.banner-column {
        flex-direction: row;
        background-color: #071400;
        font-family: BourgeoisUltra, Roboto, sans-serif;
        font-size: 2.8rem;
        font-weight: 800;
        padding: 20px;
        align-items: stretch;
        span {
          padding: 0 20px;
          border-left: 2px solid white;
          border-right: 2px solid white;
          &:first-child {
            border-left: 4px solid white;
          }
          &:last-child {
            border-right: 4px solid white;
          }
        }
      }
      &.text-column {
        flex-direction: column;
        background-image: url('../../assets/images/section_2_bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 60px 0;
        ul {
          max-width: 80vw;
          li {
            font-family: Bourgeois, Roboto, sans-serif;
            font-size: 1.8rem;
            padding: 20px 0;
            list-style-type: disc;
            list-style-position: inside;
            // &:before {
            //   content: '';
            //   margin-left: -15px;
            // }
            a {
              color: white;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

/* MOBILE */
@media (max-width: 992px) {
  .Section2 {
    .columns {
      margin-left: auto;
      margin-right: auto;
      .column {
        &.banner-column {
          font-size: 0.6rem;
          font-weight: 600;
          padding: 10px 5px;

          span {
            padding: 0 5px;
            border-left: 1px solid white;
            border-right: 1px solid white;
            &:first-child {
              border-left: 2px solid white;
            }
            &:last-child {
              border-right: 2px solid white;
            }
          }
        }
        &.text-column {
          padding: 60px 0;

          ul {
            max-width: 90vw;
            li {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
