.NotFound404 {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .header {
    h1 {
      font-weight: 600;
      font-size: 10rem;
    }
  }
  .text {
    font-size: 3rem;
    font-weight: 600;
  }
  .description {
    font-size: 1.5rem;
    padding-top: 20px;
    color: rgba(128, 128, 128, 0.9);
    .link {
      text-decoration: underline;
    }
  }
}
