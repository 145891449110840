@import '../../assets/sass/font-face';

.Section5 {
  .columns {
    padding: 50px 0 80px 200px;
    color: black;
    .column {
      display: flex;
      justify-content: center;
      flex-direction: column;
      &.text-column {
        align-items: flex-start;
        padding: 0 100px 0 0;
        font-size: 1.5rem;
        h2 {
          max-width: 500px;
          line-height: 3rem;
          color: #dc0506;
          font-family: BourgeoisUltra, Roboto, sans-serif;
          font-size: 3rem;
          font-weight: 800;
          padding-bottom: 10px;
        }
        p {
          font-family: Bourgeois, Roboto, sans-serif;
          padding-bottom: 30px;
        }
        ul {
          font-family: Bourgeois, Roboto, sans-serif;
          padding-bottom: 30px;
          li {
            padding-bottom: 5px;
            list-style-type: disc;
            list-style-position: inside;
          }
        }
        a {
          color: black;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &.image-column {
        align-items: flex-end;
        padding: 30px 0 0 50px;
        img {
          height: 110%;
          max-width: 110%;
        }
      }
    }
  }
}

/* MOBILE */
@media (max-width: 992px) {
  .Section5 {
    .columns {
      margin-left: auto;
      margin-right: auto;
      padding: 60px 0 80px 0;
      .column {
        &.text-column {
          align-items: flex-start;
          padding: 10px 30px;
          font-size: 1rem;
          h2 {
            line-height: 2rem;
            font-size: 2rem;
          }
        }
        &.image-column {
          padding: initial;
          img {
            width: 80vw;
          }
        }
      }
    }
  }
}
